import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            name: 'home',
        },
    },
    {
        name: 'home',
        path: '/home',
        component: () => import('./view/home'),
        meta: {
            title: '学校财务处',
        },
    },
    {
        name: 'schoolPaid',
        path: '/school/paid',
        component: () => import('./view/school/paid'),
        meta: {
            title: '支付结果',
        },
    },


];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if ( title ) {

    //document.title = title + ' | 广西直聘人力资源集团';
    document.title = title
    
  } else {
    //document.title = '广西直聘人力资源集团';
    document.title = '';
  }
  
  next();
});

export { router };
